@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
//@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;
@include motion-ui-transitions;
@include motion-ui-animations;

html {
    font-size: 16px;
}

body {
    color: #002200;
    font-size: 0.9rem;
    background-image: url("/assets/templates/tkc/img/back.gif");
    -webkit-text-size-adjust: none;
}

a:link {
    color: #5bb430;

}

a:hover {
    color: #84d45d;

}

a:visited {
    color: #69821b;

}

p {
    margin: 5px 8px 10px;
    line-height: 150%;
    letter-spacing: 1px;
}

p.center {
    text-align: center;
}

.right {
    text-align: right;
}

.under {
    text-decoration: underline;
}

.bold {
    font-weight: bold;
}

.pink {
    color: #c53d43;
}

.fluore {
    background: linear-gradient(transparent 60%, #8effa1 60%);
}

#wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    background-color: #ffffff;
}

@media screen and (min-width: 900px) {
    #wrapper {
        border-right: solid 2px #e5e4e6;
        border-left: solid 2px #e5e4e6;
    }
}

/*---------- header ----------*/

#header-nav {
    width: 100%;
    height: 82px;
    border-top: solid 3px $global;
    position: relative;
    background: #fff;
    #sp-catch {
        width: 100%;
        height: 18px;
        line-height: 18px;
        font-size: 0.65rem;
        font-weight: bold;
        padding: 0 0 4px 10px;
        color: #000;
        background: $yellow;
    }
    #sp-logo {
        width: 64%;
        height: 58px;
        line-height: 58px;
        img {
            padding-left: 8px;
        }
    }
}

ul#c-list {
    list-style: none;
    width: 116px;
    position: absolute;
    right: 0px;
    top: 27px;
    li {
        float: left;
        margin-left: 4px;
        text-align: center;
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 8px;
        color: #fff;
        padding: 0 4px;
        font-weight: bold;
        a {
            color: #fff;
        }
        p {
            margin: 0;
            padding: 3px 0;
            line-height: 1;

            .square_title {
                font-size: 10px;
                padding: 5px 0;
            }
            .square_icon {
                font-size: 14px;
            }
        }
    }

    li.access_squre {
        background-color: $blue;
    }

    li.menu_squre {
        background-color: $orange;
    }
}

#sp-header-info {
    width: 100%;
    img {
        z-index: 10;
    }
}

@media screen and (min-width: 600px) {
    #header-nav {
        height: 98px;
        #sp-catch {
            font-size: 0.7rem;
            padding: 0 0 4px 10px;

        }
        #sp-logo {
            height: 72px;
            line-height: 72px;
        }

    }
    ul#c-list {
        list-style: none;
        width: 130px;
        position: absolute;
        right: 4px;
        top: 30px;
        li {
            float: left;
            margin-left: 8px;
            text-align: center;
            display: block;
            width: 56px;
            height: 56px;
        }
    }
}

@media screen and (min-width: 768px) {
    #catch {
        width: 100%;
        margin: 0;
        padding: 4px 0 6px 5px;
        height: 20px;
        line-height: 12px;
        font-weight: normal;
        font-size: .7rem;
        text-align: left;
        color: #002200;
        background-color: #FCF7B4;
        border-top: solid 2px $global;
    }
    #header {
        margin: 0;
        padding: 0 0 0 0;
        width: 100%;
        height: 130px;
        background-image: url("/assets/templates/tkc/img/back_med2.png");
        position: relative;
        background-position: right top;
        background-repeat: no-repeat;
        background-size: cover;
        #header-logo {
            margin: 0;
            padding: 20px 0 0 5px;
            height: 72px;
            line-height: 72px;
            max-width: 345px;
            a:hover img {
                opacity: 0.6;
                filter: alpha(opacity=60);
                -ms-filter: "alpha( opacity=60 )";
                background: #F3FAFE;
            }
        }
        ul#c-list {
            list-style: none;
            width: 70px;
            position: absolute;
            right: 4px;
            top: 22px;
            li {
                float: none;
                margin-top: 4px;
                text-align: center;
                display: block;
                width: 56px;
                height: 56px;
            }
            li.access_squre {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 900px) {
    #header {
        position: relative;
        background: url("/assets/templates/tkc/img/back34.png"),
        url("/assets/templates/tkc/img/back_left.png");
        background-position: right bottom, left bottom;
        background-repeat: no-repeat, no-repeat;
        background-size: cover, contain;
        border-bottom: solid 4px #FCF7B4;
        padding: 0;
        height: 130px;
        #header-logo {
            margin: 0;
            padding: 20px 0 0 10px;
            text-align: left;
            img {}
            a:hover img {
                opacity: 0.6;
                filter: alpha(opacity=60);
                -ms-filter: "alpha( opacity=60 )";
                background: #F3FAFE;
            }
        }
        #tel {
            position: absolute;
            right: 10px;
            top: 5px;
            width: 200px;
            height: 25px;
        }
        #address {
            position: absolute;
            right: 20px;
            top: 45px;
            font-size: 0.7rem;
            text-decoration: none;
            text-align: left;
            color: #444;
        }
        ul#c-list {
            display: none;
        }
    }
}

@media screen and (min-width: 980px) {
    #header {
        background-size: contain, contain;
    }
}

/*---------- off-canvas ----------*/

.off-canvas {
    ul.sb-menu {
        display: table;
        margin: 15px auto;
        list-style: none;
        li,
        li a {
            color: #fff;
            padding: 8px 0 8px;
            list-style: none;
        }
    }
    ul.outerlink {
        display: table;
        margin: 15px auto;

        li,
        li a {
            list-style: none;
            color: #fff;
            padding: 8px 0 8px;
        }
        li.disc {
            list-style-type: disc;
            margin-left: 20px;
        }
    }
}

/*---------- gnav ----------*/

@media screen and (min-width: 900px) {
    #wrapper .top-bar {
        height: 45px;
        margin: 0;
        padding: 0;
        .dropdown {
            li {
                list-style: none;

                ul li a {
                    font-size: .7rem;
                    padding: 13px 10px 11px 12px;
                }

            }
            li.active a {
                border-bottom: solid 3px $orange;
            }
            li.active ul li a {
                border-bottom: none;
            }
            a {
                height: 42px;
                padding: 13px 20px 11px 22px;
                color: #fffffc;
                font-size: .8rem;
                text-align: center;
                font-weight: bold;
                text-decoration: none;
                text-shadow: 0 1px 0 #000;
                border-right-width: 1px;
                border-right-style: solid;
                border-right-color: #47a20d;
            }
            a:hover {
                color: #FFA07A;
                background-color: #83D157;
                text-shadow: none;
            }
        }
    }

    #wrapper .dropdown.menu > li.is-dropdown-submenu-parent > a::after {
        border-color: #fff transparent transparent;
    }
}

/*---------- content ----------*/

#container {
    width: 100%;
}

#maincontent {
    width: 100%;
    padding: 5px 10px 10px;
}

@media screen and (min-width: 900px) {
    #maincontent {
        width: 98%;
        padding: 5px 10px 10px;
    }
}

#topicpath {
    font-size: .8rem;
    margin: 5px -10px 10px;
    background: #e9edf5;
    padding: 6px 5px 0px 8px;
    overflow: hidden;
    ul {
        margin: 0 0 8px 0;

        li {
            display: inline;
            list-style: none;
            a {
                text-decoration: none;
                color: #273376;
            }
        }
        li:after {
            font-family: FontAwesome;
            content: '\f101';
            padding: 0 3px;
            color: #8186a0;
        }
        li:last-child:after {
            content: '';
        }
        li:first-child a:before {
            font-family: FontAwesome;
            content: '\f015';
            font-weight: normal;
            font-size: 1.1em;
        }
    }
}

#maincontent h1 {
    position: relative;
    font-size: 1.3rem;
    font-weight: bold;
    margin: 30px 10px 5px;
    padding: 0.5em 0.5em 0.1em 1.1em;
    border-bottom: 2px solid #B9503B;
}

#maincontent h1:before {
    content: "◇";
    font-size: 150%;
    position: absolute;
    color: #ff6b6e;
    top: -0.8em;
    left: 0.3em;
    height: 12px;
    width: 12px;
}

#maincontent h1:after {
    content: "◇";
    font-size: 150%;
    position: absolute;
    color: #B9503B;
    top: -0.7em;
    left: 0;
    height: 12px;
    width: 12px;
}

#maincontent h2 {
    width: 98%;
    position: relative;
    margin: 20px auto 20px;
    padding: 0.6em 0.8em;
    background: $orange;
    color: #fff;
    font-size: .95rem;
    font-weight: bold;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

#maincontent h2:after {

    position: absolute;
    bottom: -14px;
    left: 10%;
    z-index: 5;
    margin-left: -15px;
    border-top: 15px solid $orange;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 0;
    content: "";
}

#maincontent h3 {
    margin: 5px 3px 5px 0px;
    padding: 0 10px 9px 0px;
    line-height: 140%;
    font-size: 0.95rem;
    font-weight: bold;
    color: #000;

}

#maincontent h4 {
    margin: 5px 3px 5px 0px;
    padding: 0 10px 9px 0px;
    line-height: 140%;
    font-size: 1rem;
    font-weight: bold;
    color: $brown;

}

#maincontent h5 {
    margin: 10px 3px 5px 3px;
    padding: 10px 10px 5px 3px;
    line-height: 110%;
    font-size: .85rem;
    color: #583822;
    a {
        color: #5bb430;
    }
    a:hover {
        color: #84d45d;

    }
}

/*---------- サイド ----------*/

#sidemenu {
    width: 100%;
    text-align: left;
    padding: 0;
    background: #F9F8F4;
    h2 {
        height: 30px;
        line-height: 10px;
        padding: 10px 0 10px 18px;
        font-size: .8rem;
        color: #fffffc;
        background: #B9503B;
        width: 100%;
        font-weight: bold;

    }
    .side_space {
        margin: 0 auto;
        padding: 5px 0;
        width: 100%;
        a {
            width: 90%;
        }
    }
    .side_space1 {
        margin: 0 auto;
        width: 100%;
    }
    .side_cal {
        margin: 0 auto;
        padding: 15px 0;
        width: 90%;
        iframe {
            width: 100%;
        }
        h2 {
            height: 28px;
            padding: 7px 0 4px 22px;
            font-size: .8rem;
            color: #fffffc;
            background-image: url("/assets/templates/tkc/img/side_title.jpg");
            background-repeat: no-repeat;
            background: left;
        }
    }

    #holiday_side {
        width: 100%;
        .holiday {
            padding: 15px 10px;
        }
    }

    .time img {
        width: 100%;
    }
    /*---------- クリニック情報 ----------*/
    .information {
        display: table;
        margin: 5px auto;
        p {
            font-size: .75rem;
            margin: 2px 0 10px;
            padding: 0px 3px 0 13px;
            position: relative;
        }
        p:before {
            font-family: 'FontAwesome';
            content: "\f101";
            color: $global;
            font-weight: bold;
            font-size: .9rem;
            position: absolute;
            top: -1px;
            left: 0;
        }
    }
    p.bold {
        padding: 5px 0 10px 30px;
        font-weight: bold;
        color: $global;
        font-size: .9rem;

    }

    /*---------- 休診日お知らせ ----------*/
    #holiday_side {
        width: 100%;
        font-weight: bold;
    }
    #holiday_side .holiday {
        display: table;
        margin: 10px auto;
        color: #5bb430;

    }
    #holiday_side a {
        color: #5bb430;
        text-decoration: none;
    }
    #holiday_side .holiday p {
        font-size: 1rem;
        margin: 6px 2px 6px 5px;
        font-weight: bold;
    }

    #holiday_side a:hover {
        opacity: 0.6;
        filter: alpha(opacity=60);
        -ms-filter: "alpha( opacity=60 )";
        background: #F3FAFE;
    }
    #holiday_side .date {
        display: none;

    }
    #holiday_side h4 {
        display: none;

    }
    #holiday_side p.more {
        display: none;
    }
    #holiday_side ul {
        margin-bottom: -10px;
    }
    #holiday_side ul li {
        color: #5bb430;
        list-style-type: square;
        margin-left: -20px;

    }
    /*---------- 診療時間表 ----------*/
    .time img {
        width: 100%;
    }
    a:hover img {
        opacity: 0.6;
        filter: alpha(opacity=60);
        -ms-filter: "alpha( opacity=60 )";
        background: #F3FAFE;
    }

}

@media screen and (min-width: 600px) {
    #sidemenu .search {
        fieldset {
            width: 400px;
            margin: 25px auto 10px;
            label #ajaxSearch_input {
                width: 300px;
                color: #666;
                float: left;
                display: inline;
            }
            label #ajaxSearch_submit {
                width: 80px;
                float: right;
                padding-top: .2em;
                margin-top: 8px;
                display: inline;
            }
        }
    }
    /* highlight results */
    .AS_ajax_highlight1,
    .ajaxSearch_highlight1 {
        color: #000;
        font-weight: 700;
        padding: 0;
        background: #FFFF00;
    }

    .AS_ajax_highlight2,
    .ajaxSearch_highlight2 {
        color: #DF5380;
        font-weight: 700;
        padding: 0;
    }

    .AS_ajax_highlight3,
    .ajaxSearch_highlight3 {
        color: #DF53B5;
        padding: 0;
        font-weight: bold;
    }

    .AS_ajax_highlight4,
    .ajaxSearch_highlight4 {
        color: #DF5380;
        padding: 0;
        font-weight: bold;
    }

    .AS_ajax_highlight5,
    .ajaxSearch_highlight5 {
        color: #A453DF;
        padding: 0;
        font-weight: bold;
    }

    .AS_ajax_highlight6,
    .ajaxSearch_highlight6 {
        color: #53ADDF;
        padding: 0;
        font-weight: bold;
    }

    #sidemenu .information {
        p {
            font-size: 1rem;
            margin: 2px 0 10px;
            padding: 0px 3px 0 13px;
            position: relative;
        }
        p:before {
            font-family: 'FontAwesome';
            content: "\f101";
            color: $global;
            font-weight: bold;
            font-size: 1.1rem;
            position: absolute;
            top: -1px;
            left: 0;
        }
        p.bold {
            padding: 5px 0 10px 30px;
            font-weight: bold;
            color: $global;
            font-size: 1.1rem;
        }
    }
}

@media screen and (min-width: 768px) {
    #sidemenu {
        width: 100%;
        padding: 10px 10px;
        .side_space {
            margin: 0 0 5px 0px;
            padding: 0 0px 5px;
            width: 100%;
            text-align: left;
            h2 {
                height: 32px;
                margin: 0 0 10px 0;
                padding: 8px 0 4px 10px;
                font-size: .76rem;
                line-height: 16px;
            }
            a {
                color: #5bb430;
                width: 198px;
                padding: 6px 0 5px 0;
                font-size: .9rem;
            }
            a:hover {
                color: #84d45d;
            }
            p {
                margin: 0;
                padding: 5px 8px 5px 8px;
                font-size: .8rem;
                line-height: 120%;
            }
            span.brown_b {
                color: #993300;
                font-weight: bold;
            }
            .link p {
                padding: 5px 10px 20px;
            }
            .information {
                display: table;
                margin: 5px auto;
                max-width: 94%;
                p {
                    font-size: .70rem;
                    margin: 2px 0 10px 5px;
                    padding: 0px 3px 0 13px;
                    position: relative;
                }
                p:before {
                    font-family: 'FontAwesome';
                    content: "\f101";
                    color: $global;
                    font-weight: bold;
                    font-size: .8rem;
                    position: absolute;
                    top: -1px;
                    left: 0;
                }
            }

            .res_ba {
                margin: 0 auto 0px;
                text-align: center;
                width: 100%;
            }
        }

        /*---------- 休診日カレンダー ----------*/
        .side_cal {
            margin: 0 auto;
            padding: 0;
            text-align: left;
        }

        /*---------- 問診票 ----------*/
        .monshin {
            display: table;
            margin: 10px auto;
            padding: 8px 5px 3px 5px;
            p {
                font-size: .7rem;
                line-height: 120%;
                a {
                    line-height: 100%;
                    padding: 0px 5px 0px 5px;
                    font-size: .7rem;
                }
            }
            p.adobe {
                padding: 10px 8px 10px 15px;
                font-size: .66rem;
                color: #666666;
            }

        }
        .search {
            fieldset {
                width: 94%;
                margin: 25px auto 5px;
                label #ajaxSearch_input {
                    width: 72%;
                    color: #666;
                    float: left;
                    display: inline;
                }
                label #ajaxSearch_submit {
                    width: 22%;
                    float: right;
                    padding-top: .2em;
                    margin-top: 8px;
                    display: inline;
                }
            }

        }
    }
}

@media screen and (min-width: 900px) {
    #sidemenu {
        width: 100%;
        margin: 10px 0 0 0;
        padding: 0;
    }
}

/*---------- footer ----------*/

#footer {
    width: 100%;
    padding: 0 0 8px 0;
    text-align: center;
    color: #002200;
    line-height: 120%;
    background-color: #FCF7B4;
    border-top: solid 3px $global;
    border-bottom: solid 3px $global;


    ul#footmenu {
        clear: both;
        overflow: hidden;
        display: table;
        width: 96%;
        margin: 10px auto 5px;
        li {
            margin: 5px 0 0 5px;
            border-left: none;
            list-style: none;
            float: left;
            a {
                display: inline-block;
                line-height: 40px;
                vertical-align: middle;
                padding: 0 10px;
                background: #FFF;
                border: solid 1px #CCC;
                border-radius: 6px;
                text-decoration: none;
                margin: 0 0 6px 0;
                font-size: 0.85rem;
            }
        }
    }
    p {
        padding: 5px 10px;
        font-size: 0.7rem;
    }
    .copy {
        clear: both;
        font-style: italic;
        padding-top: 0px;
        font-size: .7rem;
    }
}

@media screen and (min-width: 768px) {
    #footer {
        margin: 0;
        padding: 10px 0 12px 0;
        a {
            color: #5bb430;
            font-size: 0.7rem;
            text-decoration: none;
        }

        a:hover {
            color: #84d45d;
            text-decoration: none;
        }
        ul#footmenu {
            margin: 15px auto;
            display: table;
            width: 90%;
            li.start {
                margin: 0px;
                padding: 0px 6px 0px 0px;
                display: inline;
                line-height: 100%;
                border-left: none;
            }
            li {
                margin: 0px;
                padding: 0px 6px 0px 7px;
                display: inline;
                line-height: 100%;
                border-left: 1px solid #5bb430;
                a {
                    border: none;
                    background: none;
                    line-height: 28px;
                }
            }
        }
    }
}

/*---------- page-top ----------*/

#page-top {
    position: fixed;
    bottom: 60px;
    right: 5px;
    background: url(/assets/templates/tkc/img/btn_pagetop.png);
    height: 22px;
    width: 95px;
}

#page-top a:hover {
    text-decoration: none;
    background: #999;
}

/*---------- news_box ----------*/

#introduce {
    margin: 10px;
}

#holiday .news_box {
    padding: 10px 20px 20px;
    h4 {
        padding-bottom: 5px;
        padding-top: 15px;
    }
}

#news .news_box {
    width: 96%;
    margin: 10px auto 15px;
    padding: 20px 20px 20px;
}

.news_box {

    #news_date {
        text-align: right;
        color: $brown;
        padding: 0 5px 5px 0;
    }
    h4 {
        margin-top: 0;
        padding-top: 0;
    }
    dt {
        position: relative;
        padding-left: 18px;
    }
    dt:before {
        font-family: 'FontAwesome';
        content: "\f1bb";
        color: $global;
        position: absolute;
        left: 0px;
    }
}

@media screen and (min-width: 768px) {
    .news_box {
        width: 96%;
        margin: 10px auto;
        padding: 5px 20px 15px;

    }
}

/*---------- article ----------*/

article {
    padding: 5px 10px 5px;
    margin-bottom: 15px;
}

/*---------- FAQ ----------*/

dl.faq {
    padding: 0 0 5px 10px;
    width: 96%;
    dt {
        padding: 7px 0 4px 0.9rem;
        font-size: .95rem;
        color: $blue;
        font-weight: bold;
        position: relative;

    }
    dt:before {
        font-family: 'FontAwesome';
        content: "\f101";
        color: $blue;
        font-weight: bold;
        font-size: 0.95rem;
        position: absolute;
        top: 6px;
        left: 0;
    }
    dd {
        font-size: .9rem;
        color: #002200;
    }
}

/*---------- ワクチン料金表 ----------*/

.price {
    width: 100%;
    margin: 0;
    padding: 0;
    table {
        width: 100%;
        margin: 10px auto;
        line-height: 150%;
        th {
            height: 30px;
            background: #eeeeff;
        }
        td {
            font-size: 100%;
            color: #002200;
        }
    }
}

.price table th,
.price table td {
    text-align: center;
    min-width: 25px;
    height: 40px;
    border: 1px solid #ddd;
}

table.price3 {
    border-spacing: 0;
    font-size: .8rem;
}

table.price3 th {
    color: #fff;
    padding: 8px 8px;
    background: #258;
    background: -moz-linear-gradient(rgba(34, 85, 136, 0.7), rgba(34, 85, 136, 0.9) 50%);
    background: -webkit-gradient(linear, 100% 0%, 100% 50%, from(rgba(34, 85, 136, 0.7)), to(rgba(34, 85, 136, 0.9)));
    font-weight: bold;
    border-left: 1px solid #258;
    border-top: 1px solid #258;
    border-bottom: 1px solid #258;
    line-height: 120%;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(34, 85, 136, 0.9);
    box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.3) inset;
}

table.price3 th:first-child {
    border-radius: 5px 0 0 0;
}

table.price3 th:last-child {
    border-radius: 0 5px 0 0;
    border-right: 1px solid #258;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(255, 255, 255, 0.3) inset;
}

table.price3 tr td {
    padding: 8px 8px;
    border-bottom: 1px solid #84b2e0;
    border-left: 1px solid #84b2e0;
    text-align: center;
}

table.price3 tr td:last-child {
    border-right: 1px solid #84b2e0;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
}

table.price3 tr {
    background: #fff;
}

table.price3 tr:nth-child(2n + 1) {
    background: #f1f6fc;
}

table.price3 tr:last-child td {
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
}

table.price3 tr:last-child td:first-child {
    border-radius: 0 0 0 5px;
}

table.price3 tr:last-child td:last-child {
    border-radius: 0 0 5px 0;
}

table.price3 tr:hover {
    background: #bbd4ee;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .price {
        width: 95%;
        margin: 15px auto;
    }
    table.price3 {
        width: auto;
        border-spacing: 0;
        font-size: .9rem;
        margin: 0 auto;
        th,
        td {
            padding: 8px 15px;
        }
    }

}

/*---------- ワクチン　----------*/

ol.list_parentheses {
    padding: 0 0 0 2em;
    margin: 10px 0;
}

ol.list_parentheses li {
    list-style-type: none;
    list-style-position: inside;
    counter-increment: cnt;
    margin-bottom: 6px;
}

ol.list_parentheses li:before {
    display: marker;
    content: "(" counter(cnt) ") ";
}

ul.vaccine {
    margin: 15px auto;
    display: table;
    font-size: .95rem;
    li {
        margin-bottom: 6px;
    }
}

/*---------- Access ----------*/

.il_map {
    width: 90%;
    max-width: 640px;
    margin: 20px auto;
    display: table;
}

ul.access {
    margin: 10px auto;
    display: table;
    font-size: 0.9rem;
}

#map {
    width: 96%;
    padding: 10px;
    margin: 15px auto 5px;
    height: 420px;
}

/*---------- job ----------*/

dl.job {
    padding-top: 8px;
    dt {
        color: $brown;
        font-weight: bold;
        font-size: 1rem;
        padding-top: 6px;
    }
    dd {
        font-size: 0.9rem;
    }
}
